import React from "react";
import "./footer.css";


const TheFooter = () => {

    return <React.Fragment>
        <footer className="my-5">
            <div className="footer text-center">
                <strong>I.Y.A.A.Y.A.S</strong>
                <p>v04.18.2023</p>
            </div>
        </footer>
    </React.Fragment>
}

export default TheFooter;