import React from "react";
import {
    SiCplusplus, SiCsharp, SiJavascript, SiReact, SiDotnet,
    SiMicrosoftsqlserver, SiBootstrap, SiPostman, SiVisualstudio,
    SiVisualstudiocode, SiUnity, SiGit, SiJquery, SiHtml5, SiCss3,
    SiTrello
} from "react-icons/si";

const Skill = ({ name }) => {
    switch (name) {
        case "C#":
            return <SiCsharp />
        case "JavaScript":
            return <SiJavascript />
        case "C++":
            return <SiCplusplus />
        case "DotNet":
            return <SiDotnet />
        case "React":
            return <SiReact />
        case "MSSql":
            return <SiMicrosoftsqlserver />
        case "HTML5":
            return <SiHtml5 />
        case "CSS3":
            return <SiCss3 />
        case "BootStrap":
            return <SiBootstrap />
        case "JQuery":
            return <SiJquery />
        case "Visual Studio":
            return <SiVisualstudio />
        case "VS Code":
            return <SiVisualstudiocode />
        case "Git":
            return <SiGit />
        case "Postman":
            return <SiPostman />
        case "Trello":
            return <SiTrello />
        case "Unity":
            return <SiUnity />
        default: <></>
            break;
    }
}

export default Skill;