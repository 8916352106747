import React from "react";
import Typewriter from 'typewriter-effect';
import "./aboutMe.css";

const typewriterOptions = {
    strings: ["Software Engineer", "Leader", "Problem Solver"],
    autoStart: true,
    loop: true,
    delay: 80,
    pauseFor: 1000,
}

const AboutMe = () => {



    return <React.Fragment>
        <div className="container-xxl pt-5">
            <div className="row row-cols-2">
                <div className="col-12 col-lg-6">
                    <h5 className="text-success small-intro">Hi, my name is</h5>
                    <h3 className="text-light">Bryan Cardeno</h3>
                    <h5 className="text-light">
                        {"I am a "}
                        <Typewriter
                            options={typewriterOptions}>
                        </Typewriter>
                    </h5>
                    <p className="text-light" id="about-details">
                        I am incredibly passionate about technology and a game enthusiast.
                        I have an extensive background in Full Stack development.
                        As an Air Force Veteran, I am confident in working effectively in a team, versatile, and a quick learner.
                        I strive to improve my skills continuously and aim for mastery.
                    </p>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default AboutMe;