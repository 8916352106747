import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./contactInfo.css";

const ContactInfo = ({ isModalOpen, modalHandleClose }) => {

    const [contactInfo, setContactInfo] = useState({
        name: "",
        message: "",
        email: ""
    })

    const formOnChange = e => {
        const target = e.target;

        setContactInfo(prevState => {
            const newState = { ...prevState };
            newState[target.name] = target.value;
            return newState;
        })
    }

    return <React.Fragment>
        <Modal
            show={isModalOpen}
            onHide={modalHandleClose}
            size="large"
            className="w-100"
            backdrop="static"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    Contact Information
                </Modal.Title>
            </Modal.Header>
            <div className="modal-title-border"></div>
            <Modal.Body>
                <div className="container px-1">
                    <form>
                        <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label mb-1">Name:</label>
                            <input type="text" id="full-name" name="name" className="form-control" value={contactInfo.name} onChange={formOnChange} />
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="email" className="form-label mb-1">Email:</label>
                            <input type="email" id="email" name="email" className="form-control" value={contactInfo.email} onChange={formOnChange} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message" className="form-label mb-1">Message:</label>
                            <textarea name="message" id="message" rows={5} className="form-control" value={contactInfo.message} onChange={formOnChange} />
                        </div>

                        <div className="modal-submit-container d-flex justify-content-center">
                            <button disabled className="btn btn-primary mt-3 mb-2 w-25 py-2" onClick={() => { console.log("close button pressed") }} >Submit</button>
                        </div>

                    </form>
                </div>

            </Modal.Body>

        </Modal>
    </React.Fragment>
}

export default ContactInfo;