import React from "react";
import { BsFillFolderSymlinkFill } from "react-icons/bs";
import "./projects.css";
import MiVet from "../../misc/MiVet.jpeg";
import MiVetAppointment from "../../misc/MiVetAppointment.gif";


const Projects = () => {

    return <React.Fragment>
        <div className="container container-fluid my-5">
            <h1 className="text-center text-light mb-5">Projects</h1>
            <div className="row mx-auto px-md-5 px-xxl-0">
                <div className="col-12 col-lg-12 col-xxl-3 details-column pt-3 me-xxl-2 mb-4 mb-xxl-0 px-0">

                    <div className="row details-description text-center px-2">
                        <p><strong><u><h5>MiVet</h5></u></strong> </p>
                        <p>is startup web application modernizing veterinary services</p>
                        <h5>Appointments and Booking System</h5>
                        <p>Displays customer/vet schedule and its details. Booking system sends confirmation email to both customer and vet via
                            <strong> SendGrid</strong>
                        </p>
                    </div>
                    <div className="details-link text-light px-4 text-end">
                        <div>
                            <a href="https://github.com/BryanCardeno/Mi-Vet" target="_blank" rel="noreferrer noopener" className="text-secondary">
                                <BsFillFolderSymlinkFill />
                            </a>
                        </div>

                    </div>
                </div>
                <div className="col-12 col-lg-4 col-xxl-6 display-column px-0 me-4 ms-xl-4 ms-xxl-0 mb-4 mb-lg-0">
                    <img src={MiVet} alt="..." className="rounded" />
                    <a href="https://mivet.azurewebsites.net/" target="_blank" rel="noreferrer noopener">
                        <img src={MiVetAppointment} alt="..." className="gif rounded" />
                    </a>
                </div>
                <div className="col-12 col-lg-3 col-xl-4 col-xxl-3 tech-stack">
                    <div className="text-center container container-fluid pt-4 text-light">
                        <h5>Tech Stack</h5>
                    </div>
                    <div className="text-center text-light">
                        <code>
                            <p>JavaScript</p>
                            <p>C#</p>
                            <p>React</p>
                            <p>.NET</p>
                            <p>MS SQL</p>
                        </code>
                    </div>

                </div>
            </div>
        </div>
    </React.Fragment >
}

export default Projects;