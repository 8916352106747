import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import "./navbar.css";


const TheNavBarV2 = ({ setIsModalOpen }) => {

    const contactHandler = () => {
        setIsModalOpen(() => true);
    }

    return <React.Fragment>
        <Navbar collapseOnSelect
            expand="md"
            fixed="top">
            <div className="container-xxl py-2">
                <div>
                    <h5 className="text-light my-auto">Bryan Cardeno</h5>
                </div>
                <Navbar.Toggle aria-controls="navbar-menu" />
                <Navbar.Collapse id="navbar-menu">
                    <div className=" d-flex text-light">
                        <h6 className="my-auto">
                            <Nav.Link href="#intro" className="mx-3 mb-2 mb-md-0">
                                <button className="nav-link border-0">about</button>
                            </Nav.Link>
                            <Nav.Link href="#projects" className="mx-3 mb-2 mb-md-0">
                                <button className="nav-link border-0">project</button>
                            </Nav.Link >
                            <Nav.Link href="#skills" className="mx-3 mb-2 mb-md-0">
                                <button className="nav-link border-0">skills</button>
                            </Nav.Link>
                            <Nav.Link className="mb-2 mb-md-0">
                                <button className="mx-3 nav-link border-0" onClick={contactHandler}>contact</button>
                            </Nav.Link>
                            <Nav.Link href="https://drive.google.com/uc?export=download&id=1_OmHagnUGLDubVKU3ON3xrdQgy_P2Nsy" download className="mx-3 mb-2 mb-md-0">
                                <button className="nav-link border-0">resume</button>
                            </Nav.Link>
                        </h6>
                        <h5>
                            <Nav.Link href="https://www.linkedin.com/in/bryan-cardeno/" target="blank" className="mx-3">
                                <FaLinkedin className="text-light nav-link" />
                            </Nav.Link>
                            <Nav.Link href="https://github.com/BryanCardeno" target="blank" className="mx-3">
                                <FaGithub className="text-light nav-link" />
                            </Nav.Link>
                        </h5>
                    </div>




                </Navbar.Collapse>
            </div>


        </Navbar>
    </React.Fragment>
}

export default TheNavBarV2;