import React from "react";
import "./contactInfo.css";

const Connection = ({ setIsModalOpen }) => {

    const clickHandler = () => {
        console.log("clicking connection");
        setIsModalOpen(() => true);
    }

    return <React.Fragment>
        <div className="text-center text-light connection">
            <div id="connection-header" onClick={clickHandler} className="mb-3">
                <h2>
                    Let's Connect!
                </h2>
            </div>
            <div>
                <p>You're also welcome to reach me at <a id="email-anchor" href="mailto:bryan.cardeno@gmail.com">bryan.cardeno@gmail.com</a></p>
            </div>
        </div>
    </React.Fragment >
}

export default Connection;