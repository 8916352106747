import './App.css';
import React from 'react';
import TheNavBarV2 from './components/navbar/TheNavBarV2';
import AboutMe from './components/about/AboutMe';
import Skills from './components/skills/Skills';
import Projects from './components/projects/Projects';
import TheFooter from './components/footer/TheFooter';
import { useState } from 'react';
import ContactInfo from './components/contact/ContactModal';
import Connection from './components/contact/Connection';


function App() {

  const [isModalOpen, setIsModalOpen] = useState(false);



  const modalHandleClose = () => {
    setIsModalOpen(() => false);
  }


  return <React.Fragment>
    <TheNavBarV2 setIsModalOpen={setIsModalOpen} />

    <section id="intro" className="pt-5 my-5 mt-sm-0">
      <AboutMe />
    </section>

    <section id="skills">
      <div className="container container-fluid">
        <Skills />
      </div>

    </section>

    <section id="projects">
      <Projects />
    </section>

    <section id="connection">
      <Connection setIsModalOpen={setIsModalOpen} />
    </section>

    <TheFooter />

    <ContactInfo
      isModalOpen={isModalOpen}
      modalHandleClose={modalHandleClose}
    />

  </React.Fragment>
}

export default App;
