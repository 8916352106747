import React, { useEffect, useState } from "react";
import "./skills.css"
import Skill from "./Skill";
import { skills } from "../../misc/data";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Skills = () => {

    const [skillsList, setSkillsList] = useState([]);

    useEffect(() => {
        setSkillsList(() => skills.map(skill => {
            return <React.Fragment>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip className="skill-tooltip">
                            <strong>{skill.name}</strong>
                        </Tooltip>
                    }>
                    <div className="skills-icons" style={{ color: skill.color }}>
                        <Skill name={skill.name} />
                    </div>
                </OverlayTrigger>
            </React.Fragment>
        }
        ))
    }, [])

    return <React.Fragment>
        <div className="skills-comps text-center">
            <h1 className="mb-4">Skills && Techs</h1>
            {skillsList}
        </div>
    </React.Fragment>
}

export default Skills;