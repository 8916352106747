class Skill {
    constructor(name, color) {
        this.name = name;
        this.color = color;
    }
}
export const skills = [
    new Skill("C#", "#3A018B"),
    new Skill("JavaScript", "#EFD81D"),
    new Skill("C++", "#005697"),
    new Skill("DotNet", "#592C8C"),
    new Skill("React", "#61DAFB"),
    new Skill("MSSql", "grey"),
    new Skill("HTML5", "#DD4B25"),
    new Skill("CSS3", "#254BDD"),
    new Skill("Bootstrap", "#592C8C"),
    new Skill("JQuery", "#1066A9"),
    new Skill("Visual Studio", "#7D4FB6"),
    new Skill("VS Code", "#2C7CB4"),
    new Skill("Git", "#E84D31"),
    new Skill("Postman", "#F15A28"),
    new Skill("Trello", "#0075B9"),
    new Skill("Unity", "white"),
]